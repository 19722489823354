.fig-image {
    aspect-ratio: 1/1;
    background-size: auto;
}

.search-bar {
    border: none;
    width: 100%;
    padding: 5px;
}

.search-bar:focus {
    border: none;
}

.search-bar-div {
    border: 2px solid grey;
    border-radius: 5px;
    ;
    display: flex;
    align-items: center;
    background-color: white;
    /* padding:10px; */

}

/* .search-bar-div:focus ,
.search-bar-div:hover {
     border:2px solid black; 

} */

.banner-img {
    max-height: 40vh;
}

.menu-cat-div {

    border-radius: 20px;
    margin: 10px;
}

.color1 {
    background-color: rgb(209, 243, 243);
    background-image: linear-gradient(to right, rgb(209, 243, 243), rgb(176, 194, 194), rgb(209, 243, 243));
}

.color2 {
    background-color: rgb(191, 192, 248);
    background-image: linear-gradient(to right, rgb(191, 192, 248), rgb(168, 168, 181), rgb(191, 192, 248));
}

.color3 {
    background-color: rgb(237, 189, 242);
    background-image: linear-gradient(to right, rgb(237, 189, 242), rgb(180, 167, 182), rgb(237, 189, 242));
}

.menu-cat-img {
    aspect-ratio: 1/1;
}