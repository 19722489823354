.counting-btn {
  padding: 10px 20px;
  background-color: rgb(177, 240, 183);
  color: rgb(41, 4, 4);
  font-weight: 700;
  border: 1px solid black;
}

.counting-btn:hover {
  background-color: black;
  color: aquamarine;
}

.counting {
  padding: 10px 20px;
  background-color: rgb(177, 240, 183);
  color: rgb(41, 4, 4);
  font-weight: 700;
  border: 1px solid black;
}

.discount-percent {
  color: rgb(107, 201, 107);
}

.offer-price {
  font-weight: 700;

}

.original-price {
  font-weight: 500;
  font-size: 14px;
  color: grey;
}

.save-for-later {
  max-width: 160px !important;
}

.danger-color {
  background-color: #ffa733 !important;
}

.emptyCart-img {
  max-width: 400px;
}