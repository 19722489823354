/* @import "~react-image-gallery/styles/scss/image-gallery.scss"; */
@import "~react-image-gallery/styles/css/image-gallery.css";

.deatails-box {
    width: 90%;
    /* max-width:500px; */
    aspect-ratio: 1/1 !important;

}

.productb-off-price {
    color: red;
    margin-right: 20px;
}

.productb-original-price {
    color: grey;
}

.counter-div {
    display: flex;
    /* border:2px solid rgb(170, 235, 170); */

    margin: 20px 0px;
    max-width: 300px;
}

.counterChanger {
    min-width: 80px;
    padding: 10px;
    color: black !important;
    background-color: rgb(170, 235, 170) !important;
    margin: 0px;
    cursor: pointer;
    text-align: center;
    flex: 1;
}

.counterChanger:hover {


    background-color: rgb(170, 235, 170);

}

.count {
    width: 80px;
    min-width: 80px;
    background-color: none;
    padding: 10px;
    text-align: center;
    flex: 1;

}

.my-labels {
    color: grey;
    font-weight: 700;
    margin: 10px 0px 0px 0px;
}

.add-to-cart {
    min-width: 150px !important;
}

.imagezindex {
    z-index: 1;
}


/* .imagezindexZero{
        z-index: 0;
    } */
.image-thumbs-cntnr {
    /* height:50vh; */
    max-height: 80vh;
    /* border:2px solid red; */
    padding: 10px;

    overflow-y: scroll;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    scrollbar-arrow-color: aqua;
}

.imageGal-height {
    max-height: 80vh;
}

.similar-slick-prev:before {
    color: blue !important;
}

.similar-slick-next:before {
    color: blue !important;
}

.similar-slider-div {
    width: 100%;
    margin: auto;
    /* display: flex; */
    padding-left: 5%;
    padding-right: 30px;
}