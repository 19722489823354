.slick-prev:before {
    color: blue !important;
}

.slick-next:before {
    color: blue !important;
}

.slider-div {
    width: 100%;
    margin: auto;
    padding-left: 8%;
    padding-right: 8%;
}

.max_height {
    max-height: 450px;
}

.hover-change-css:hover {

    font-size: large;
    color: blue;
    /* Color on hover */
    text-decoration: underline;
    /* Add underline on hover */
}

.hover-color-change-css {

    padding: 10px;
}

.hover-color-change-css:hover {
    color: blue;
    background-color: aqua;
}