.product-image {
    aspect-ratio: 3/2;
}

.products-card {
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
    width: 300px;
    max-width: 400px;
    padding: 0px;
    margin: 20px;
    border-radius: 20px;
    /* flex-grow: 1; */
}

.green-bg {

    background-color: rgb(242, 252, 232);
}

.red-bg {

    background-color: rgb(252, 175, 173);
}

.product-Img {
    width: 90%;
    align-self: center;
}

.rating-stars {
    height: 50px;
    width: 90px;
    margin-left: 20px;
}

.product-title {
    font-weight: 700;
    margin-left: 20px;
}

.product-price {
    margin-left: 18px;
    color: grey;
    font-weight: 700;
    color: black;
    font-size: 20px;
    /* text-decoration: line-through; */
}

.product-offer-price {
    /* margin-top: '-27px'; */
    margin-left: 18px;
    color: 'grey';
    text-decoration: line-through;
    color: red;
}

.product-add-cart-button {
    position: relative;
    bottom: 0%;
    height: 50px;
    width: 165px;
    align-self: flex-end;
    border-radius: 40px 0px 0px 15px;

}

.empty-product-image {
    width: 75%;
    max-width: 600px;
}