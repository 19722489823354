 .address-item {
   border: 1px solid #4365cb;
   padding: 20px;
   margin-bottom: 20px;
 }

 .address-item :first-child {
   border-bottom: 0 !important;
 }

 .address-item :last-child {
   border-top: 0 !important;
 }