* {
    box-sizing: border-box;
}

.cat-bg {
    display: flex;
    width: 100%;
    flex-grow: 1;
}

.cat-options {
    width: 20%;
    max-width: 200px;
    min-width: 150px;
}

.cat-details {
    width: 80%;
    /* padding:20px; */
}

/* categories sidebar List styles */
.list-btn {
    width: 100%;
    margin: 0px;
    border-radius: 0px;
    margin: 2px 0px;
    list-style-type: none;
    text-align: center;
    cursor: pointer;
    /* min-height:50px; */
    padding: 10px;
}

.list-btn:hover {
    background-color: rgb(158, 178, 194);
}

.selected-btnCls {
    background-color: rgb(157, 206, 247);
    color: rgb(6, 6, 6);

}

.cat-ul {
    padding: 0px;

}

.sub-cat-div {
    /* border:2px solid red; */
    margin: 10px 0px 20px 0px;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border:1px solid grey; */

}

.sub-cat-images {
    width: 80%;
    aspect-ratio: 3/2;

}

.sub-cat-title {
    margin: 5px;
}

.sub-cat-head {
    padding: 10px;
}