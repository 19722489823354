/* .settingsoption1{
    order: 1;
}
.settingsoption2{
    order: 1;
}
@media screen and (max-width: 480px) {
    .settingsoption1{
        order: 2;
    }
  }

  @media screen and (min-width: 480px) {
    .settingsoption1{
        order: 1;
    }
  } */

  .form-bgh{
    border:2px solid black;
    border-radius:20px;
    margin:30px;
    padding:40px;
    backdrop-filter: blur(28px);
  
  }